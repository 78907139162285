
const getLocale = async (ctx: any, acceptLanguages: any) => {
    let locale: string = acceptLanguages && acceptLanguages.length > 0 ? acceptLanguages[0] : 'en';
    try {
        let prevLocale: any = window.localStorage.getItem('locale');
        if(prevLocale) {
            return prevLocale;
        }
        let cookieLocale: any = window.localStorage.getItem('cookieLocale');
        if (ctx && ctx.locale) {
            cookieLocale = ctx.locale;
            window.localStorage.setItem('cookieLocale', cookieLocale);
        }
        if(acceptLanguages && acceptLanguages.length > 0){
            let contextLocale = (window.navigator && window.navigator.language);
            if (cookieLocale) {
                locale = acceptLanguages.includes(cookieLocale) ? cookieLocale : locale;
            }
            if(contextLocale) {
                contextLocale = contextLocale.slice(0, 2);
                locale = acceptLanguages.includes(contextLocale) ? contextLocale : locale;
            }
        }
        return locale;
    } catch (error) {
        console.error(error);
        return locale;
    }
};

export default getLocale;
