import * as React from 'react';
import Head from 'next/head';
import App, { AppContext } from 'next/app';
import Router, { NextRouter } from 'next/router';

import getConfig from 'next/config';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ComingSoon from '@components/coming-soon';
import { IntlProvider } from 'evl-ui-lib';
import { theme } from '../jss';
import { getMessages } from '@utils';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'react-slideshow-image/dist/styles.css';
import getLocale from 'utils/getLocale';

const { publicRuntimeConfig } = getConfig();

NProgress.configure({ showSpinner: false, easing: 'ease', speed: 500 });

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

declare global {
  interface Window {
    Modernizr: { flexbox: boolean; arrow: boolean };
  }
}

interface AppProps {
  ctx: any;
  prevLocale: string;
  prevMessages: any;
  router: NextRouter;
  earlyAccessCode?: string;
}

interface AppState {
  isLoggedIn: boolean;
  earlyAccessCode?: string;
  locale: string;
  messages: any;
}

class MyApp extends App<AppProps> {
  state: AppState = {
    isLoggedIn: false,
    earlyAccessCode: '',
    locale: '',
    messages: {},
  };

  static async getInitialProps({ Component, ctx }: AppContext) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    let prevLocale = await getLocale({}, []);
    let prevMessages = await getMessages(prevLocale);
    const { earlyAccessCode } = ctx.query;
    if (earlyAccessCode) return { pageProps, earlyAccessCode, ctx, prevLocale, prevMessages };
    return { pageProps, prevLocale, prevMessages };
  }
  updateLocale = async() => {
    let selectedLocale = await getLocale({}, []);
    let localeMessages = await getMessages(selectedLocale);
    this.setState({locale: selectedLocale, messages: localeMessages});
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode!.removeChild(jssStyles);
    }
    this.updateLocale();
    if (this.props.earlyAccessCode) {
      localStorage.setItem('earlyAccessCode', this.props.earlyAccessCode);
      this.setState({ earlyAccessCode: this.props.earlyAccessCode });
      return;
    }
    if (!this.props.earlyAccessCode) {
      const earlyAccessCode = localStorage.getItem('earlyAccessCode');
      this.setState({ earlyAccessCode });
      return;
    }
  }

  render() {
    const { Component, pageProps, prevLocale, prevMessages } = this.props;
    const { earlyAccessCode, locale, messages } = this.state;
    return (
      <IntlProvider locale={locale ? locale: prevLocale} messages={locale ? messages: prevMessages}>
        <Head>
          <title>Everledger Wallet</title>
          <meta charSet="utf-8" />
          {/* Use minimum-scale=1 to enable GPU rasterization */}
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
          <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap" rel="stylesheet" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:400,600,700" type="text/css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway:400,700" type="text/css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Amiri" type="text/css" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Source Sans Pro:400,600,700"
            type="text/css"
          />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Mulish:400,700" type="text/css" />
          <link rel="stylesheet" href="/static/fonts.css" type="text/css" />
          <link rel="stylesheet" href="https://use.typekit.net/cjw5fck.css" />
          <link rel="icon" type="image/png" sizes="32x32" href="/static/icons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/static/icons/favicon-16x16.png" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="default" />
          <meta name="apple-mobile-web-app-title" content={publicRuntimeConfig.CLIENT_NAME} />
          <meta name="description" content={publicRuntimeConfig.META_DESC} />
          <meta name="format-detection" content="telephone=no" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="msapplication-config" content="/static/icons/browserconfig.xml" />
          <meta name="msapplication-TileColor" content={theme.palette.primary.main} />
          <meta name="msapplication-tap-highlight" content="no" />
          <meta name="theme-color" content={theme.palette.primary.main} />
          {/* Use minimum-scale=1 to enable GPU rasterization */}
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover, maximum-scale=1"
          />
          <link rel="apple-touch-icon" sizes="180x180" href="/static/icons/apple-icon.png" />
          <link href="/static/icons/apple-splash-1125.png" sizes="1125x2436" rel="apple-touch-startup-image" />
          <link href="/static/icons/apple-splash-1242.png" sizes="1242x2208" rel="apple-touch-startup-image" />
          <link href="/static/icons/apple-splash-750.png" sizes="750x1334" rel="apple-touch-startup-image" />
          <link href="/static/icons/apple-splash-640.png" sizes="640x1136" rel="apple-touch-startup-image" />
          <link rel="icon" type="image/png" sizes="32x32" href="/static/icons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/static/icons/favicon-16x16.png" />
          <link rel="manifest" href="/manifest.json" />
          {/* <link rel='mask-icon' href='/static/icons/safari-pinned-tab.svg' color='#5bbad5' /> */}
          <link rel="shortcut icon" href="/static/icons/favicon.ico" />
          <meta name="referrer" content="strict-origin" />
          <meta name="google" content="notranslate" />

          {/* [START] Google Tag Manager */}
          {publicRuntimeConfig.ENVIRONMENT === 'production' && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
              (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", "${publicRuntimeConfig.GA_TRACKING_ID}");
              `,
              }}
            ></script>
          )}
          {/* [END] Google Tag Manager */}
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {publicRuntimeConfig.COMING_SOON &&
            (!earlyAccessCode || earlyAccessCode !== publicRuntimeConfig.EARLY_ACCESS_CODE) && <ComingSoon />}
          {(!publicRuntimeConfig.COMING_SOON ||
            (publicRuntimeConfig.COMING_SOON &&
              earlyAccessCode &&
              earlyAccessCode === publicRuntimeConfig.EARLY_ACCESS_CODE)) && <Component {...pageProps} />}
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default MyApp;
